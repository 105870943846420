<template>
  <div>
    <t-row>
      <t-col :cols="(layout === 'vertical') ? '12' : '6'" :order="(layout === 'vertical') ? 1 : 2">
        <b-form-file v-model="selectedFile"
                     ref="file-input"
                     :placeholder="placeholder"
                     :state="validFile"
                     :accept="allowedTypes.join(',')"/>
      </t-col>
      <t-col :cols="(layout === 'vertical') ? '12' : '6'" :order="(layout === 'vertical') ? 2 : 1">
        <p-progress-bar :value="uploadPercentage"
                        :class="['my-3', { 'invisible': !uploadInProgress && uploadPercentage !== 100 }]">
          {{uploadPercentage === 100 ? 'Upload Complete' : `${uploadPercentage}%`}}
        </p-progress-bar>
      </t-col>
    </t-row>
  </div>
</template>

<script>
export default {
  name: 'fileUpload',
  props: {
    allowedTypes: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: null
    },
    endpoint: {
      type: String,
      default: null
    },
    layout: {
      type: String,
      default: 'vertical'
    }
  },
  data () {
    return {
      selectedFile: null,
      validFile: null,
      uploadInProgress: false,
      uploadPercentage: 0
    }
  },
  watch: {
    selectedFile (val) {
      if (val) {
        if (this.allowedTypes.includes(val?.type)) {
          this.validFile = true
          this.upload()
        } else {
          this.validFile = false
        }
      }
    }
  },
  methods: {
    async upload () {
      this.uploadInProgress = true

      const currentFile = this.selectedFile
      const formData = new FormData()
      let response = null

      formData.append('file', currentFile, currentFile.name)

      if (this.endpoint === 'export/up') {
        response = await this.$res.set.excelUpload(formData, progressEvent => {
          this.uploadPercentage = Math.round((progressEvent.loaded / progressEvent.total) * 100)
        })
      } else {
        // TODO: build out a generic file upload solution
      }

      if (response) {
        this.$emit('success', response)
      } else {
        this.$emit('failure')
      }

      this.clearFiles()

      this.uploadInProgress = false
    },
    clearFiles () {
      if (this.$refs['file-input']) {
        this.$refs['file-input'].reset()
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
